import React, { useEffect, useState } from "react"
import Layout from "../../components/layout"
import { getWenZhangType, getWenZhangList } from "../../services/wenzhang"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import "./news.scss"

let debug = require("debug")("app:news")

export default ({data}) => {
    // 资讯类型uuid
    let newsTypeUuid = ""
    // 当前加载页面
    let loadPage = 1
    // 总页面数
    let totalPage = 1
    // 每个分页包含item数量
    let pageSize = 12
    // 文章数据
    let totalData = []

    const [data1, setData1] = useState([])

    useEffect(() => {
        console.log("hello from console")
        debug("hello debug", data)

        // getNewsTypeUuid()
        //
        // window.addEventListener("scroll", loadDataOnScroll)
        // return () => {
        //     window.removeEventListener("scroll", loadDataOnScroll)
        // }
    }, [])

    const loadDataOnScroll = () => {
        let scrollHeight = document.body.scrollHeight  // 网页正文全文高度
        let clientHeight = document.body.clientHeight  // 玩也可视区域高度
        let scrollY = window.pageYOffset // 文档在垂直方向已经滚动的像素值
        // console.log('>>>>', scrollHeight, clientHeight, scrollY)

        // 距离页面底部小于200px时开始加载新数据
        if (scrollHeight - clientHeight - scrollY < 200) {
            if (loadPage < totalPage) {
                loadPage++
                getNewsPagedList(loadPage)
            }
        }
    }

    const getNewsTypeUuid = async () => {
        if (newsTypeUuid !== "") {
            return
        }
        let params = {
            "wyUuid": "e75217e3-aef2-4fe0-80ba-7a5a7954ea7c",
            "type": "guanwangzixun",
        }
        let rsp = await getWenZhangType(params)
        if (rsp.err === 0) {
            newsTypeUuid = rsp.data.uuid
            await getNewsPagedList(loadPage)
        }
    }

    /**
     * 加载分页页面资讯数据，并更新存储总数据
     */
    const getNewsPagedList = async (pageNumber) => {
        let param = {
            page: pageNumber,
            size: pageSize,
            typeUuid: newsTypeUuid,
        }
        let rsp = await getWenZhangList(param)
        if (rsp.err === 0) {
            // 拼接返回的数据和已经加载过的数据，并从新渲染资讯列表
            totalData = totalData.concat(rsp.data)
            totalPage = rsp.page
            setData1(totalData)
        }
    }

    return (
        <Layout pageId={"/news"}>
            <Helmet>
                <title>果心科技 | 新闻动态</title>
                <meta name="keywords" content="果心科技 - 新闻资讯动态"/>
                <meta name="description" content="产品介绍 | 行业动态 | 公司动态"/>
            </Helmet>
            <h1 className="text-center my-5">
                新闻动态
            </h1>
            <div id="news-list-body" className="container my-5">
                <div className="row justify-content-start">
                    {/*{*/}
                    {/*    data.allInternalPosts.nodes.map((item) => {*/}
                    {/*        return (*/}
                    {/*            <div key={item.uuid} className="col-12 col-lg-4 p-2">*/}
                    {/*                <div className="news-item">*/}
                    {/*                    <Link to={"/news/detail?uuid=" + item.uuid}>*/}
                    {/*                        <div className="news-item-img"*/}
                    {/*                             style={{ backgroundImage: `url(http://pcdn.guosim.com/${item.tupianUrl})` }}>*/}
                    {/*                            <img className="img-fluid" src="../../images/empty-cover.png" alt=""/>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="news-item-body">*/}
                    {/*                            <div className="caption py-3 px-3">{item.wenzhangBiaoti}</div>*/}
                    {/*                            <div className="pb-3 px-3">*/}
                    {/*                                {(item.tags || "").split(",").map(item => {*/}
                    {/*                                    return <span className="badge badge-secondary mr-1">{item}</span>*/}
                    {/*                                })}*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </Link>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        )*/}
                    {/*    })*/}
                    {/*}*/}
                </div>
            </div>
        </Layout>
    )
};
// export const query = graphql`
//   query {
//     allInternalPosts{
//     nodes {
//       id
//       commentStatus
//       crtaetRen
//       fabuRenyuan
//       fabuShijian
//       isDelete
//       isFullscreen
//       isSettop
//       seoDesc
//       seoKeywords
//       seoTitle
//       status
//       tags
//       tupianUrl
//       typeUuid
//       uuid
//       wenzhangBiaoti
//       wenzhangDianji
//       wenzhangDianzan
//       wenzhangNeirong
//     }
//   }
//   }
// `
